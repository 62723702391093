<template>
    <div class="page-service">
        <h1>Services et Prestations</h1>
        <!-- <router-link to="/Contact"> Contact</router-link> -->
        <br>
        <div class="prestation">
        <br>
        <br>
          <div class="box-verif">
            <div class="titre-verif">
              Vérifications périodiques et entretien des instruments de pesage
            </div> 
            <li class="text-verif">CNP assure la vérification périodique de vos instruments de pesage, 
              un service indispensable pour garantir leur conformité légale. Pour les équipements liés à la vente 
              au public, une vérification tous les 2 ans est requise pour ceux de moins de 30 kg, et annuellement 
              pour les autres. Les instruments de plus de 5 tonnes nécessitent une vérification annuelle, 
              accompagnée d'une révision tous les 2 ans. Nous proposons également 
              des services de réparation et de maintenance pour tous types d'appareils de pesage, afin de garantir 
              leur bon fonctionnement et leur précision en permanence.
            </li>
            <br>
          </div>
          <div class="box-vente">
            <div class="titre-vente">
              Vente et contrôle interne de matériel de pesage
            </div>
            <li class="text-vente">
              Nous proposons une large gamme d'instruments de pesage adaptés à tous vos besoins : balances de 
              précision, balances poids-prix, balances industrielles, ponts bascules pour poids lourds, ponts 
              rails, ainsi que des systèmes de pesage pour silos et camions poubelles. En plus de la vente, nous 
              effectuons des contrôles internes et offrons des services de dépannage pour assurer leur conformité 
              et leur performance. Notre expertise nous permet de répondre aux exigences spécifiques de chaque 
              client, des petites entreprises aux grandes installations industrielles.
            </li>
            <br>
          </div>
          </div>
        </div>
        <div class="demande">

          <h1 class="demande" style="text-decoration: underline;">Demande de Services:</h1>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <div class="form-devis">
            <form action="https://formsubmit.co/cnp1985.54@gmail.com" method="POST">
              <input type="hidden" name="_subject" value="Demande de service !">
              <b>Sujet de la demande d'information</b>
              <input type="text" name="Sujet de la demande d'information" required placeholder="Sujet de la demande d'information*">  
              <b>Nom :</b>
              <input type="text" name="Nom" required placeholder="Nom*">
              <b>Adresse mail :</b>
              <input type="email" name="email" required placeholder="Email Address*">
              <b>Numéro de téléphone :</b>
              <input type="text" name="Numéro de téléphone" required placeholder="Numéro de téléphone*">
              <b>Entreprise :</b>
              <input type="text" name="Entreprise" required placeholder="Entreprise*">
              <b>Votre demande de service :</b>
              <textarea type="text" name="Demande" required placeholder="Votre demande de service.*"></textarea>
              <button type="submit">Envoyer</button>
              <input type="hidden" name="_replyto" value="Merci pour votre demande de Service !">
              <input type="hidden" name="_autoresponse" value="Merci pour votre demande de Service !">
              <input type="hidden" name="_template" value="box">
            </form>
          </div>
        </div>
          <!-- prestation -->
          <!-- VP : verification périodique -->
          <!-- vp obligatoir tous les 2 ans si vente direct au public, pour les portées inferieur à 30kg et tous les ans  dans tous les autres cas, pour les instruments de plus de 5t une vp tous les ans et une vp + revision périodique tous les 2 ans. (vignette verte)  -->
          <!-- controle intern, réparation tous type de pesage, 
          Vente de balance (balance de presision, poids prix / jusqu'aux / balance indus, pont bascule,  pont rail, pesage de silos, camion de poubelle) ainsi que des controles intern sur le meme type de matériels -->
          
        <!-- réparation | -->

        <!-- mesure d'humidité (humidimètre et proteine) -->
        <!-- proteine determine le taux de prot, humidimettre sert à mesurer le taux d'humidité dans differentes céréals -->
        
        <br>
        <br>
        <!-- Obligation des utilisateurs -->
         <!-- 5 points
           1- Faire verifier ses instruments à usage réglementé :
              - Tous les 2 ans pour la vente direct au public de portée inférieur ou égale à 30kg
              - Tous les ans, dans les autres cas
           2- S'assurer :
              - que le client soit satisfait
         -->

</template>


<style scoped>
h1 {
  position: relative;
  text-align: center;
  top: 6vh;
  font-size: 3vw;
}

.page-service {
  position: relative;
  width: 100%;
  top: 10vh;
  height: 160vh;
}

div {
  text-align: center;
}

.box-verif {
  position: relative;
  width: 70%;
  height: 100%;
  top: 6vh;
  left: 15%;
  font-family: 'Lato', sans-serif;
}

.box-vente {
  position: relative;
  width: 75%;
  height: 100%;
  top: 6vh;
  left: 15%;
}

.titre-vente {
  position: relative;
  font-size: 2.2vw;
  top: 6vh;
  font-family: 'Lato', sans-serif;
  color: #6878c2;
}

.titre-verif {
  color: #6878c2;
  position: relative;
  top: 6vh;
  font-size: 2.2vw;
  font-family: 'Lato', sans-serif;
}

.prestation {
  text-align: left;
}

.demande {
  position: relative;
  top: 10vh;
}

.text-verif {
  position: relative;
  list-style-type: none; /* Supprime les puces */
  text-align: center;
  padding: 15px;
  margin: 20px;
  top: 6vh;
  font-size: 1.5vw;
  font-family: 'Lato', sans-serif;
}

.text-vente {
  position: relative;
  list-style-type: none; /* Supprime les puces */
  text-align: center;
  padding: 15px;
  margin: 20px;
  top: 6vh;
  font-size: 1.5vw;
  font-family: 'Lato', sans-serif;
}

 router-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}

/*
router-link:hover {
  color: red;
} */
</style>