<template>
    <div class="page-qsn">
        <img class="image-acc" src="../../assets/facade.jpg" alt="secteurs">
        <br>
        <div class="bandeau_titre">
            <div class="partenaire_titre">Qui sommes nous ?</div>
            <div class="barre_blanche"></div>
        </div>
        <br>
        <br>
        <br>
        <div class="titre-qsn"> 
            Qui sommes nous ?
        </div> 
        <br>
        <br>
        <br>
        <div class="histoire-cnp"> <br><br> Fondée en 1985 par une équipe de techniciens spécialisés dans le pesage, CNP s'est rapidement imposée comme un acteur clé dans le domaine des ponts bascules routiers et ferroviaires.
            <br><br>
            <br><br>
            À l'époque, l'industrialisation de la Lorraine a favorisé le développement rapide de l'entreprise. Nos locaux, comprenant ateliers et bureaux, sont situés dans la zone industrielle de Prouvé, au nord de Nancy, offrant ainsi une position géographique stratégique.
            <br><br>
            <br><br>
            CNP se distingue par son expertise en métrologie légale, spécialisée dans les équipements de pesage destinés au commerce, à l'industrie et aux PME. Nous avons également développé une expertise dans la mesure d'humidité des céréales, avec des humidimètres conformes à une réglementation proche de celle des instruments de pesage.
        <br><br>
        <br><br>
        </div>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>



<style>

.page-qsn {
    /* position: relative; */
    width: 100%;
    /* top: 10vh; */
    height: 250vh;
}

@import url('https://fonts.cdnfonts.com/css/century-schoolbook');

.titre-qsn {
    font-size: 3vw;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.histoire-cnp {
    position: absolute;
    width: 70%;
    height: 100%;
    left: 15%;
    /* border: 2px solid rgba(0, 0, 0, 0.5) */
    border-radius: 10px;
    color: black;
    text-align: center;
    font-size: 1.6vw;
    font-family: 'Lato', sans-serif;
    overflow-y: none;
    transition: 0.5s ease-in-out;
    letter-spacing: 0.05vw;

}

/* .histoire-cnp:hover {
    transform: scale(1.2);
    background-color: rgba(18, 62, 99, 0.7);
} */

.titre-quisommesnous:hover {
    transform: scale(1.2);
    background-color: rgba(18, 62, 99, 0.9);
}


h1 {
    text-align: center;
}

div {
    text-align: center;
}

</style>