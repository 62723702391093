<template>
  <div>
  <div class="triangle"></div>
  <router-link to="/">
    <img class="logo" src="../assets/logo.png" alt="Logo">
  </router-link>
  <nav class="navbar">
    <router-link 
      to="/" 
      :class="{'active-link': $route.path === '/'}"
    >Accueil</router-link>
    <router-link 
      to="/QuiSommesNous" 
      :class="{'active-link': $route.path === '/QuiSommesNous'}"
    >Qui sommes nous ?</router-link>
    <div class="dropdown">
      <div class="dropdown-toggle">Produits <div class="arrow">&#9650;</div></div>
      <ul class="dropdown-menu">
        <li style="pointer-events: none;"></li>
        <li><router-link to="/PesageStatique">Pesage Statique</router-link></li>
        <!-- <li><router-link to="/PesageDynamique">Pesage Dynamique</router-link></li> --> 
        <li><router-link to="/Humidimetre">Humidimètre</router-link></li>
      </ul>
    </div>

    <div class="dropdown2">
      <div class="dropdown-toggle2">Services et Métrologie <div class="arrow">&#9650;</div></div>
      <ul class="dropdown-metro">
        <li style="pointer-events: none;"></li>
        <li><router-link to="/Services">Services et Prestations</router-link></li>
        <li><router-link to="/Metrologie">Métrologie</router-link></li>
      </ul>
    </div>

    <router-link to="/Partenaire"> Partenaire</router-link>
    <router-link to="/Contact"> Contact</router-link>
    <span class="navbar"></span>
  </nav>
  <router-view></router-view>
  <footer class="footer">
    <div class="triangle-bas">
      <router-link to="/">
        <img class="logo-bas" src="../assets/logo.png" alt="Logo-bas">
      </router-link>
    </div>
    <div class="horaire-bloc">
      <ul class="horaire-footer">
        <h3 class="titre-horaire">Horaires:</h3>
        <li>✅ Lun 8h-12h 13h30-17h15</li>
        <li>✅ Mar 8h-12h 13h30-17h15</li>
        <li>✅ Mer 8h-12h 13h30-17h15</li>
        <li>✅ Jeu 8h-12h 13h30-17h15</li>
        <li>✅ Ven 8h-12h 13h30-17h15</li>
        <li>❌ Samedi</li>
        <li>❌ Dimanche</li>
      </ul>
    </div>
    <div class="contact-bloc">
      <ul class="contact-footer">
        <router-link class="router-text" to="/Contact">
          
          <h3 class="titre-contact">Contact :</h3>
          <li>📞 : +33 3 83 30 14 44</li>
          <li>🏠 : 27 Rue André Fruchard,<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            54320 Maxéville
          </li>
          <li>📧 : <a href="mailto:arthur1schuster@gmail.com">cnp@cnp-pesage.com</a> </li>
        </router-link>
      </ul>
    </div>
  </footer>
  <div class="black-footer">
    <router-link class="date_cnp" to="/">© 1985-2024 CNP &nbsp;&nbsp;&nbsp;•</router-link>
    <router-link class="mention-leg" to="/MentionLegal">Mention Légale</router-link>
  </div>
</div>
</template>

<script>
  document.addEventListener('DOMContentLoaded', () => {
    const dropdown = document.querySelector('.dropdown');
    const dropdownMenu = document.querySelector('.dropdown-menu');
    let timeoutId;
    
    dropdown.addEventListener('click', () => {
      dropdownMenu.classList.toggle('open'); // Ajoute ou retire la classe "open" au clic
    });
    dropdown.addEventListener('mouseenter', () => {
      clearTimeout(timeoutId); // Annuler la fermeture si la souris est encore sur Produits
      dropdownMenu.style.display = 'block';
    });

    dropdown.addEventListener('mouseleave', () => {
      timeoutId = setTimeout(() => {
        dropdownMenu.style.display = 'none'; // Fermer le menu après 1s
      }, 500); // 1000ms = 1 seconde
    });
    

    dropdownMenu.addEventListener('mouseenter', () => {
      clearTimeout(timeoutId); // Si la souris revient sur le menu, annuler le timeout
    });

  });


  document.addEventListener('DOMContentLoaded', () => {
    const dropdown2 = document.querySelector('.dropdown2');
    const dropdownMetro = document.querySelector('.dropdown-metro');

    if (!dropdown2 || !dropdownMetro) {
        console.error('Les éléments .dropdown2 ou .dropdown-metro ne sont pas trouvés.');
        return;
    }

    let timeoutId;

    dropdown2.addEventListener('click', () => {
        dropdownMetro.classList.toggle('open'); // Ajoute ou retire la classe "open" au clic
    });

    dropdown2.addEventListener('mouseenter', () => {
        clearTimeout(timeoutId); // Annuler la fermeture si la souris est encore sur Produits
        dropdownMetro.style.display = 'block';
    });

    dropdown2.addEventListener('mouseleave', () => {
        timeoutId = setTimeout(() => {
            dropdownMetro.style.display = 'none'; // Fermer le menu après 500ms
        }, 500);
    });

    dropdownMetro.addEventListener('mouseenter', () => {
        clearTimeout(timeoutId); // Si la souris revient sur le menu, annuler le timeout
    });
});
</script>

<!-- prezi -->

<style>
.container {
    margin: 0 auto; /* Centre horizontalement */
    height: auto; /* Hauteur minimale égale à la fenêtre */
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

@media screen and (max-width: 1499px) {
  /* Ajustement du footer */
  .footer {
    height: 45vh; /* Réduction de la hauteur du footer */
    padding: 1%; /* Espacement intérieur en pourcentage */
  }

  .black-footer {
    width: 110%;
    left: -10%; /* Ajustement dynamique */
  }

  /* Ajustement des blocs horaires et contact */
  .horaire-bloc,
  .contact-bloc {
    left: 5%; /* Utilisation de pourcentage pour l'espacement */
    font-size: 90%; /* Réduction de la taille du texte */
    bottom: 1em;
  }


  /* Ajustement du conteneur principal */
  .page-container {
    padding-bottom: 20vh;
  }

  /* Ajustement des logos */
  .logo {
    width: 8%; /* Taille réduite du logo */
  }

  .logo-bas {
    width: 15%;
    bottom: -10em;
    right: -50%;
  }

  /* Ajustement des triangles */
  .triangle {
    width: 120vw; /* Taille du triangle en pourcentage */
    height: 80vh; /* Ajuste sa hauteur */
    right: 25%; /* Position relative */
  }

  .triangle-bas {
    width: 40vw;
    height: 30vh;
    right: -120%;
  }

  /* Navbar */
  .navbar {
    width: 90%; /* Largeur réduite de la navbar */
    gap: 3%; /* Réduction de l'espacement */
    padding: 1%; /* Espacement en pourcentage */
  }

  .navbar a {
    font-size: 1.8vh; /* Taille réduite du texte */
  }

  .navbar span {
    width: 6%; /* Largeur adaptative de l'élément de fond */
  }

  /* Menu déroulant */
  .dropdown-menu,
  .dropdown-metro {
    width: 20%; /* Largeur adaptative */
    top: 5vh; /* Ajustement vertical */
  }

  /* Ombres réduites */
  .dropdown-menu,
  .dropdown-metro {
    box-shadow: 0 1vh 2vw rgba(0, 0, 0, 0.15);
  }

  .dropdown-menu li,
  .dropdown-metro li {
    padding: 0.3em; /* Espacement réduit */
  }

  .dropdown-toggle, .dropdown-toggle2 {
    gap: 1vw; /* Réduction de l'espacement entre les éléments */
  }
}


html {
  scroll-behavior: smooth;
}

.date_cnp {
  position: relative;
  right: -1vw;
  font-size: 1.2vw;
  text-decoration: none;
  color: white;
}

.mention-leg {
  position: relative;
  font-size: 1.2vw;
  left: 2vw;
  text-decoration: none;
  color: white;
}

.mention-leg:hover {
  text-decoration: underline;
  color: #41739c;
}

.black-footer {
  position: relative;
  width: 100vw;
  height: 2.7vh;
  left: 0;
  /* top: 173em; */
  background-color: #000000;
  text-align: left;
  z-index: 2;
}

.footer {
  position: relative;
  flex: 1 0 auto;
  width: 100%; /* Ajustement dynamique */
  bottom: 0; /* Assure que le footer reste en bas */
  left: -1em;
  height: 53.7vh; /* Une hauteur qui fonctionne pour les grands écrans */
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  padding: 1em; /* Espace intérieur */
  box-sizing: border-box;
  background-color: #ffffff;
}

.horaire-bloc {
  position: relative;
  text-align: left;
  left: -16vw;
  bottom: 4.8vh;
  font-size: 1.2vw;
  font-family: 'Lato', sans-serif;
  transition: 0.5s ease-in-out;
}

.horaire-bloc:hover {
  scale: 1.2;
}

.horaire-footer li{
  list-style-type: none;
}

.titre-horaire {
  font-size: 2.5vw;
}

.contact-bloc {
  position: relative;
  text-align: left;
  left: 2vw;
  bottom: 14.8vh;
  font-size: 1.2vw;
  font-family: 'Lato', sans-serif;
  transition: 0.5s ease-in-out;
}


.contact-bloc:hover {
  scale: 1.2;
}

.contact-footer li{
  list-style-type: none;
}

.titre-contact {
  font-size: 2.5vw;
}

.router-text {
  text-decoration: none;
  color: black;
}

.navbar a.router-link-active {
  color: rgb(255, 255, 255); /* Couleur blanche pour le lien actif */
  font-style: oblique;
  text-decoration : underline;
}

/* .navbar span.router-link-active {
  opacity: 1;
  color:#000000;
} */

/* .titre-footer {
  position: relative;
  color: rgb(0, 0, 0);
  font-size: 170%;
  left: 450px;
  bottom: 250px;
  text-decoration: underline;
  z-index: 10;
} */

.logo {
  position:absolute;
  width: 6%;
  height: auto;
  top: 2%;
  left: 2%;
  border-radius: 1em;
  box-shadow: 3px 3px darkgray;
  z-index: 2;
  transition: transform 0.5s ease;
}

body {
    width: 100vw; /* Assure que le corps occupe toute la largeur de la fenêtre */
    height: 100vh;
    margin: 0; /* Supprime les marges par défaut du navigateur */
    overflow-x: hidden; /* Empêche le débordement horizontal */
}

.page-container {
  position: relative;
  padding-bottom: 45vh; /* Assure qu'il y ait assez d'espace pour les éléments bas */
}

.logo-bas {
  position: relative;
  width: 20%;
  height: auto;
  bottom: -50%;
  right: 15%;
  border-radius: 1em;
  box-shadow: 3px 3px darkgray;
  z-index: 2;
  rotate: 90deg;
  transition: transform 0.5s ease-in-out;
}


.triangle-bas {
  position: relative; /* Positionnement par rapport au parent ou à la fenêtre */
  bottom: -1em; /* Place l'élément tout en bas */
  right: -75%; /* Place l'élément tout à droite */
  width: 29vw;
  height: 50vh;
  background: radial-gradient(circle at top left, white 10%, rgba(18, 62, 99, 1) 70%);
  transform: rotate(0deg); /* Pas de rotation nécessaire pour un triangle standard */
  clip-path: polygon(
    0% 0%,   /* Sommet supérieur gauche */
    100% 100%, /* Coin inférieur droit */
    0% 100%  /* Coin inférieur gauche */
  );
  rotate: 270deg;
  z-index: 1;
}

.logo:hover {
  transform: scale(1.2);
  transform: translateX(10px);
}

.logo:active {
  transform: scale(0.9);
}

.logo-bas:hover {
  transform: scale(1.1);
}

.logo-bas:active {
  transform: translateY(4px);
}

.triangle {
    position: absolute;
    right: 35%;
    bottom: 15%;
    width: 150vw;
    height: 100vh;
    background: radial-gradient(circle at bottom left, white 50%, rgba(18, 62, 99, 1) 90%);
    clip-path: polygon(0 0, 100% 0, 0 100%);
    pointer-events: none;
    z-index: 1;
}

.navbar {
  position: absolute;
  top: 2%;
  right: 0%;
  width: 57%;
  height: 2%;
  display: flex;
  gap: 6%;
  padding: 1% 2%; 
  border-radius: 20px;
  align-items: center;
  box-shadow: 2px 2px 8px rgba(18, 62, 99, 1);
  background-color: rgba(18, 62, 99, 0.9);
  opacity: 1;
  z-index: 4;
}

.navbar a, .dropdown-toggle, .dropdown-toggle2 {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 2.1vh;
  font-weight: bold;
  z-index: 6;
}

.navbar a:hover {
  color: rgb(255, 255, 255);
}

.navbar span {
  position: absolute;
  top: 0;
  left: 0;
  width: 8%;
  height: 62%; /* Hauteur restée fixe car en pourcentage */
  background: rgb(169, 169, 169);
  border-radius: 20px;
  z-index: 5;
  transition: .5s ease 0s ease-out;
  opacity: 0;
}

.navbar a:active~span {
  background: linear-gradient(120deg, #696f75, rgb(169, 169, 169));
  transform: scale(0.9);
}

/* Acceuil */
.navbar a:nth-child(1):hover~span {
  opacity: 70%;
  width: 8%;
  left:0%;
  color: black;
}

/* Qui sommes nous ? */
.navbar a:nth-child(2):hover~span {
  left: 12%;
  width: 15%;
  opacity: 70%;
}

/* Produits */
.navbar a:nth-child(3):hover~span {
  left: 35%;
  opacity: 70%;
}

/* Services et métrologie */
.navbar a:nth-child(4):hover~span {
  left: 50%;
  opacity: 70%;
}

/* Partenaire */
.navbar a:nth-child(5):hover~span {
  left: 73.5%;
  width: 8.5%;
  opacity: 70%;
}

/* Contact */
.navbar a:nth-child(6):hover~span {
  left: 86.5%;
  width: 8%;
  opacity: 70%;
}

.dropdown {
  position: relative;
}

.dropdown2 {
  position: relative;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0.2vw 0.2vw;
  gap: 2vw;
}

.dropdown-toggle2 {
  display: flex;
  align-items: center; 
  padding: 0.2vw 0.2vw;
  gap: 2vw;
}


.arrow {
  font-size: 8px;
  transition: transform 0.5s ease; /* Transition douce pour la rotation */
  display: inline-block;
  margin-left: -0.5vw;
  color: rgb(255, 255, 255);
}

/* Menu déroulant caché par défaut */
.dropdown-menu {
  display: none; /* Caché par défaut */
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  text-align: left;
  width: 12vw; /* Ajustement de la largeur avec vw */
  top: 4vh; /* Positionnement en dessous du lien en utilisant vh */
  left: -2vw; /* Déplacement horizontal en vw */
  background-color: rgba(18, 62, 99, 0.8);
  box-shadow: 0 1.5vh 2vw rgba(0, 0, 0, 0.2); /* Ombre adaptée à l'écran */
  border-bottom-left-radius: 0.8em; /* Utilisation de em pour les arrondis */
  border-bottom-right-radius: 0.8em;
  z-index: 5;
}

.dropdown-metro {
  display: none; /* Caché par défaut */
  list-style: none;
  padding: 0;
  margin: 0;
  width: 15vw; /* Largeur en vw */
  position: absolute;
  text-align: left;
  top: 4.6vh; /* Positionnement vertical en vh */
  left: -1vw; /* Positionnement horizontal en vw */
  background-color: rgba(18, 62, 99, 0.8);
  box-shadow: 0 1.5vh 2vw rgba(0, 0, 0, 0.2); /* Ombre avec vh et vw */
  border-bottom-left-radius: 0.8em; /* Utilisation d'em pour l'arrondi */
  border-bottom-right-radius: 0.8em;
  z-index: 5;
}

.dropdown-menu li {
  padding: 0.5em; /* Utilisation de em pour les espacements */
}

.dropdown-metro li {
  padding: 0.5em; /* Utilisation de em pour les espacements */
}


.dropdown-menu li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.dropdown-metro li a {
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.dropdown-menu li:hover {
  background: linear-gradient(120deg, #123E63, rgb(221, 221, 221));
  border-radius: 8px;
}

.dropdown-metro li:hover {
  background: linear-gradient(120deg, #123E63, rgb(221, 221, 221));
  border-radius: 8px;
}

.dropdown-menu li:active {
  background-color: #ddd;
  border-radius: 8px;
}

.dropdown-metro li:active {
  background-color: #ddd;
  border-radius: 8px;
}


.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown2:hover .dropdown-metro {
  display: block;
}

.dropdown:hover .arrow {
  transform: rotate(180deg); 
}

.dropdown2:hover .arrow {
  transform: rotate(180deg); 
}

.dropdown-menu:hover {
  display: block;
}

.dropdown-metro:hover {
  display: block;
}

</style>
