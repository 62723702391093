
<template>
    <div>
        <img class="image-acc" src="../../assets/photo/instal_pontbs.jpg" alt="secteurs">
        <br>
        <div class="bandeau_titre">
            <div class="partenaire_titre">Pesage Statique</div>
            <div class="barre_blanche"></div>
        </div>
    </div>
        <br>
        <br>
        <br>
<!-- brail
 presition
 ranger count
 plateforme de pese


  
  -->
        <!-- faire photo pb defilante vers la gauche, css only ou un peu de js (métalique, metalobéton, béton, pont bascule fosse, hors sol + diff hauteur) -->


        <div>
        <div class="img-pb">
            <!-- <div class="slide-s"><img src="../../assets/photo/pb/image_pb_1.png" alt="Image 1" /></div> -->
            <!-- <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_3.jpg" alt="Image 2" /></div> -->
                    <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_fosse.png" alt="Image 3" /></div>
                   <!-- <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_hors_sol.png" alt="Image 4" /></div>  -->


                   <!-- <div class="slide-s"><img src="../../assets/photo/pb/image_pb_1.png" alt="Image 1" /></div>
                    <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_3.jpg" alt="Image 2" /></div>
                    <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_fosse.png" alt="Image 3" /></div>
                    <div class="slide-s"><img src="../../assets/photo/pb/pont_bascule_hors_sol.png" alt="Image 4" /></div>              -->
        </div>
        <div class="text-pb">
            <!-- Les ponts bascules CNP, compacte avec capteurs de pesage disponible dans le monde entier, sont disponibles en versions encastrée ou hors-sol. Grâce à leur faible hauteur, ces équipements sont faciles à installer, avec ou sans travaux de génie civil, offrant ainsi une solution flexible et rapide pour diverses applications. -->
            Les ponts bascules proposés par CNP sont disponibles en version encastrée ou hors sol, métallique, métallo-béton ou béton. <br> Des versions faible hauteur, avec ou sans châssis, permettant ainsi une facilité d’installation, avec ou sans travaux de génie civil, offrant ainsi une solution flexible et rapide.
            <br> <br>
        </div>
    </div>
        <br>
        <br>
        <div>
            <img class="img-silo" src="../../assets/photo/ohaus_balance1.jpg">
            <div class="text-silo">
                CNP propose des balances de haute précision idéales pour les laboratoires, commerces et applications industrielles légères. Ces balances offrent une pesée précise, un comptage de pièces fiable et des fonctionnalités modernes comme des affichages numériques intuitifs et une connectivité avancée.
            </div>
        </div>
        <br>
        <div>
            <img class="img-carre1" src="../../assets/photo/ohaus_defender.jpg">
            <div class="text-carre1">
                Pour les environnements exigeants, nous fournissons des balances robustes adaptées aux charges lourdes, au pesage, au contrôle de poids et au comptage. Conçues pour des secteurs comme l'agroalimentaire ou l'industrie, elles allient durabilité et simplicité d'utilisation, avec des options inoxydables pour garantir l’hygiène.
            </div>
        </div>
        <div class="zone-texte-et-images">
            <div class="texte-explicatif">
                CNP propose différentes gammes de balances :  
                <br><br>
                Des balances de haute précision idéales pour les laboratoires,  
                <br>
                Des balances de commerces (poids prix),  
                <br>
                Des balances industrielles.  
                <br><br>
                Ces balances offrent une pesée précise, un comptage de pièces fiable et des fonctionnalités modernes comme des affichages numériques intuitifs et une connectivité avancée.
            </div>
            <div class="images-container">
                <img class="image-balance" src="../../assets/photo/Precision.jpg" alt="Balance 1">
                <img class="image-balance" src="../../assets/photo/Ranger_Count.jpg" alt="Balance 2">
                <img class="image-balance" src="../../assets/photo/plateforme_au_sol.jpg" alt="Balance 3">
            </div>
        </div>


        <h1 style="text-decoration: underline;">Demande de devis ou contact :</h1>
        <div class="form-devis">
            <form action="https://formsubmit.co/cnp1985.54@gmail.com" method="POST">
                <input type="hidden" name="_subject" value="Devis Pesage Statique !">
                <b>Sujet de la demande d'information</b>
                <input type="text" name="Sujet de la demande d'information" required placeholder="Sujet de la demande d'information*">
                <b>Nom :</b>
                <input type="text" name="Nom" required placeholder="Nom*">
                <b>Adresse mail :</b>
                <input type="email" name="email" required placeholder="Adresse mail*">
                <b>Numéro de téléphone :</b>
                <input type="text" name="Numéro de téléphone" required placeholder="Numéro de téléphone*">
                <b>Entreprise :</b>
                <input type="text" name="Entreprise" required placeholder="Entreprise*">
                <b>Votre demande de contact ou de devis :</b>
                <textarea type="text" name="Demande" required placeholder="Votre demande de devis ou d'informations.*"></textarea>
                <button type="submit">Envoyer</button>
                <input type="hidden" name="_replyto" value="Merci pour votre demande de Devis ou d'information et bienvenu chez CNP.">
                <input type="hidden" name="_autoresponse" value="Merci pour votre demande de Devis ou d'information et bienvenu chez CNP.">
                <input type="hidden" name="_template" value="box">
            </form>
        </div>
</template>


<style>

h1 {
    text-align: center;
}

.zone-texte-et-images {
  position: relative;
  width: 60vw; /* Largeur de la zone principale */
  margin: 5vh auto; /* Centré horizontalement et espacement vertical */
  background-color: #f5f5f5; /* Fond léger */
  border-radius: 15px; /* Arrondi des bords */
  padding: 3vw; /* Espacement interne */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Légère ombre */
  text-align: center; /* Centrer le texte */
  top: -18vh;
}

.texte-explicatif {
    font-size: 1.5vw; /* Taille du texte adaptative */
    line-height: 1.5; /* Espacement entre les lignes */
    color: #000000; /* Couleur du texte */
    margin-bottom: 2vh; /* Espace entre le texte et les images */
}

.images-container {
    display: flex; /* Positionne les images en ligne */
  justify-content: center; /* Centre les images */
  gap: 2vw; /* Espacement entre les images */
}

.image-balance {
  width: 12vw; /* Largeur adaptative des images */
  height: auto; /* Préserver les proportions */
  border-radius: 10px; /* Arrondi des coins */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Légère ombre */
  transition: transform 0.3s ease; /* Animation au survol */
}

.image-balance:hover {
  transform: scale(1.05); /* Agrandit légèrement l'image au survol */
}

.img-pb .slide-s {
    width: 100%; /* Chaque slide occupe la totalité du conteneur */
    height: 100%; /* S'adapte à la hauteur du conteneur */
    display: flex; /* Centrage de l'image dans le conteneur */
    align-items: center;
    justify-content: center;
}

.img-pb .slide-s img {
    width: 100%; /* Ajuste la largeur de l'image pour remplir le conteneur */
    height: 100%; /* Ajuste la hauteur de l'image */
    object-fit: cover; /* S'assure que l'image remplit le conteneur sans distorsion */
    border-radius: inherit; /* Adopte le même arrondi que le conteneur */
}


@keyframes slider-horizontal {
    0% {
        transform: translateX(0); /* Position de départ */
    }
    100% {
        transform: translateX(-500%); /* Finit à la fin de toutes les slides */
    }
}

.banner{
    width: 100%;
    height: 100vh;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.banner .slider{
    position: absolute;
    width: 200px;
    height: 255px;
    left: calc(50% - 100px);
    border-radius: 15px;
    transform-style: preserve-3d;
    transform: perspective(1000px);
    animation: autoRun 20s linear infinite;
    z-index: 2;
}

@keyframes autoRun{
    from{
        transform: perspective(1000px) rotateX(0deg) rotateY(0deg);
    }to{
        transform: perspective(1000px) rotateX(0deg) rotateY(120deg);
    }to{
        transform: perspective(1000px) rotateX(0deg) rotateY(240deg);
    }to{
        transform: perspective(1000px) rotateX(0deg) rotateY(360deg);
    }
}

.banner .slider .item{
    position: absolute;
    inset: 0 0 0 0;
    transform: 
        rotateY(calc((var(--position) - 1) * 90deg))
        translateZ(300px);
}

.banner .slider .item img{
    width: 150%;
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
}
.banner .content{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: min(1400px, 100vw);
    height: max-content;
    padding-bottom: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
}
.banner .content h1{
    font-family: 'ICA Rubrik';
    font-size: 16em;
    line-height: 1em;
    color: #25283B;
    position: relative;
}

.banner .content h1::after{
    position: absolute;
    inset: 0 0 0 0;
    content: attr(data-content);
    z-index: 2;
    -webkit-text-stroke: 2px #d2d2d2;
    color: transparent;
}
.banner .content .author{
    font-family: Poppins;
    text-align: right;
    max-width: 200px;
}
.banner .content h2{
    font-size: 3em;
}
.banner .content .model{
    width: 100%;
    height: 75vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: auto 130%;
    background-repeat: no-repeat;
    background-position: top center;
    z-index: 1;
}

.img-ps {
    position: relative;
    width: 1370px;
    height: 650px;
    top: -10px;
    left: -40px;
    margin: 0px auto;
    overflow: hidden;
    border: 1px solid #000000;
}

.img-pb {
    position: relative;
    left: 6vw;
    width: 45vw; /* Largeur relative à la largeur de la fenêtre */
    height: 45vh; /* Hauteur relative à la hauteur de la fenêtre */
    /* border: 2px solid black; */
    top: 2vh;
    border-radius: 15px; /* Ajoute un arrondi aux coins */
    text-align: center;
    overflow: hidden; /* Empêche le contenu de dépasser les limites */
    transition: 0.5s ease-in-out;
}

.img-pb:hover {
    transform: scale(1.2);
    left: 12vh;
    border: 2px solid black;
}

.text-pb {
    position: relative;
    width: 36vw;
    height: 32vh;
    /* border: 2px solid black; */
    border-color: black;
    border-radius: 15px;
    text-align: center;
    padding-top: 2vh;
    color: black;
    left: 58vw;
    bottom: 40vh;
    font-family: 'Lato', sans-serif;
    font-size: 1.6vw;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
    display: flex;               /* Active Flexbox */
    justify-content: center;      /* Centre horizontalement */
    align-items: center;
    transition: 0.1s ease-in 0.8s step-end;
    letter-spacing: 0.05vw;

}

.text-pb:hover {
    border: 2px solid black;

}

.text-silo {
    position: relative;
    width: 35vw;
    height: 30vh;
    /* border: 2px solid black; */
    border-color: black;
    border-radius: 15px;
    text-align: center;
    color: black;
    left: 4vw;
    bottom: 40vh;
    font-family: 'Lato', sans-serif;
    font-size: 1.6vw;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
    display: flex;               /* Active Flexbox */
    justify-content: center;      /* Centre horizontalement */
    align-items: center;        
    transition: 0.1s ease-in 0.8s step-end;
    letter-spacing: 0.05vw;

}

.img-silo {
    position: relative;
    left: 22vw;
    top: 7vh;
    width: 30vw; /* Largeur relative à la largeur de la fenêtre */
    height: 55vh; /* Hauteur relative à la hauteur de la fenêtre */
    /* border: 2px solid black; */
    border-radius: 15px; /* Ajoute un arrondi aux coins */
    text-align: center;
    overflow: hidden; /* Empêche le contenu de dépasser les limites */
    transition: 0.5s ease-in-out;
}

.img-silo:hover {
    border: 2px solid black;
    transform: scale(1.2);
    left: 35vh;
}

.text-silo:hover {
    border: 2px solid black;
}

.text-carre1 {
    position: relative;
    width: 38vw;
    height: 38vh;
    /* border: 2px solid black; */
    border-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 55vw;
    bottom: 55vh;
    font-family: 'Lato', sans-serif;
    font-size: 1.6vw;
    transition: 0.1s ease-in 0.8s step-end;
    overflow-y: hidden;
    display: flex;               /* Active Flexbox */
    justify-content: center;      /* Centre horizontalement */
    align-items: center;      
    letter-spacing: 0.05vw;

}

.text-carre1:hover {
    border: 2px solid black;
}

.img-carre1 {
    position: relative;
    right: 20vw;
    width: 40vw; /* Largeur relative à la largeur de la fenêtre */
    height: 55vh; /* Hauteur relative à la hauteur de la fenêtre */
    /* border: 2px solid black; */
    border-color: black;
    border-radius: 15px;
    text-align: center;
    bottom: 10vh;
    overflow-y: auto;
    transition: 0.5s ease-in-out;
}

.img-carre1:hover {
    transform: scale(1.2);
    border: 2px solid black;
    border-radius: 25px;
}

.text-carre2 {
    position: relative;
    width: 370px;
    height: 260px;
    border: 2px solid black;
    border-bottom-right-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 597px;
    bottom: 226px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 16px;
    transition: 0.5s ease-in-out;
    background-color: rgba(18, 62, 99, 0.5);
    overflow-y: auto;
    display: flex;               /* Active Flexbox */
    justify-content: center;      /* Centre horizontalement */
    align-items: center;     
    letter-spacing: 0.05vw;

}

.text-carre2:hover {
    transform: scale(1.1) translateX(30px) translateY(30px);
    border-radius: 25px;
    color: white;
    background: #123E63;
}

.img-carre2 {
    position: relative;
    width: 370px;
    height: 260px;
    border: 2px solid black;
    border-bottom-left-radius: 15px;
    border-color: black;
    text-align: center;
    color: black;
    left: 225px;
    bottom: 490px;
    transition: 0.5s ease-in-out;
    overflow-y: auto;
}

.img-carre2:hover {
    transform: scale(1.2) translateX(-40px) translateY(30px);
    border-radius: 25px;
}

.form-devis {
    display: flex;
    justify-content: center;  /* Centrer le formulaire au milieu de la page */
    align-items: center;
    width: auto;
    transition: 0.5s ease-in-out;
}

form {
    display: flex;
    flex-direction: column;  /* Les inputs les uns sous les autres */
    width: 30vw;  /* Largeur du formulaire : 30% de la largeur de l'écran */
    padding: 2vw;  /* Padding en fonction de la largeur de l'écran */
    background-color: #f5f5f5;
    border-radius: 1.5vw;  /* Bordures arrondies */
    box-shadow: 0 0.5vh 1vh rgba(0, 0, 0, 0.1);  /* Ombre douce */
    position: relative;
    bottom: 10vh;  /* Position en bas ajustée en fonction de la hauteur de l'écran */
}

input[type="text"],
input[type="email"],
textarea,
button {
    margin-bottom: 1vh;  /* Espacement vertical entre chaque élément */
    padding: 1.5vh 1.5vw;  /* Padding proportionnel à l'écran */
    border-radius: 1.5vw;  /* Bords arrondis */
    border: 0.2vh solid #ccc;  /* Bordure ajustée */
    font-size: 1.8vh;  /* Taille du texte adaptative */
    width: 100%;  /* Occupe toute la largeur disponible */
    box-sizing: border-box;  /* Inclure padding dans la largeur totale */
}

textarea {
    height: 20vh;  /* Hauteur plus grande pour la zone de texte */
    width: 100%;  /* Occupe toute la largeur */
    resize: none;  /* Empêche l'utilisateur de redimensionner */
    border-radius: 1.5vw;
    padding: 1.5vh 1.5vw;  /* Padding vertical et horizontal */
    font-size: 1.8vh;
    border: 0.2vh solid #ccc;
    box-sizing: border-box;  /* Inclut padding dans la largeur */
    line-height: 1.5;  /* Espacement entre les lignes */
    overflow-y: auto;  /* Défilement vertical si nécessaire */
}

button {
    background-color: #123E63;  /* Couleur du bouton */
    color: white;
    border: none;
    cursor: pointer;
    font-size: 2vh;  /* Taille du texte */
    padding: 1.5vh 2vw;  /* Padding ajusté */
    border-radius: 1.5vw;  /* Bords arrondis */
    transition: background-color 0.3s ease; /* Animation pour un effet hover */
}

button:hover {
    background-color: #0E2D4A;  /* Légère variation au survol */
}

button:hover {
    background-color: #45a049;  /* Couleur du bouton au survol */
    text-decoration: underline;
}

button:active {
    transform: scale(0.8);
}

input::placeholder, textarea::placeholder {
    color: #aaa;  /* Couleur des placeholders */
}

div {
    text-align: center;
}
</style>