
<template>
    <img class="image-metro" src="../../assets/metrologie.jpg" alt="secteurs">
    <div class="bandeau_titre">
        <div class="partenaire_titre">Métrologie Légale</div>
        <div class="barre_blanche"></div>
    </div>
    <div class="page-metro">
      <br>
      <br>
      <h1>Metrologie Légale</h1>
      <br>
      <br>
      <div class="box-metro">
      <br>
      <br>
      <br>

<br>
<br>
L'application de la réglementation métrologique peut parfois sembler complexe et avoir des répercussions économiques importantes sur vos activités quotidiennes.

<br>
<br>
Chez CNP, nous sommes spécialisés dans la fourniture et la maintenance d'instruments de pesage, en parfaite conformité avec la métrologie légale. La métrologie légale est essentielle pour garantir des transactions commerciales transparentes, équitables et sécurisées. Elle régit l’utilisation des instruments de mesure, tels que les balances et autres dispositifs de pesée, pour s'assurer que les poids mesurés sont fiables et respectent les exigences légales en vigueur.
<br>
<br>

Voici quelques points clés sur la métrologie légale dans le pesage :
<br>
<br>
<br>
<br>

<div class="titre-un">
    Homologation des instruments de pesée :
</div>
Tous les instruments de pesage utilisés dans le cadre de transactions commerciales doivent être homologués par les autorités compétentes. Cela signifie qu'ils sont vérifiés pour leur précision et leur conformité avec les normes légales. Avant leur mise en service, nos équipements sont systématiquement soumis à des tests rigoureux pour garantir leur conformité.
<br>
<br>
<br>
<br>
<div class="titre-un">
Vérification et étalonnage réguliers
</div>
La précision des instruments de pesée est essentielle. C’est pourquoi nous proposons des services d'étalonnage et de vérification périodique. Ces contrôles permettent de s'assurer que les balances et autres instruments sont toujours fiables et conformes aux normes en vigueur, en respectant les tolérances définies par la législation.
<br>
<br>
<br>
<br>

<div class="titre-un">
    Respect des tolérances légales
</div>
Chaque instrument de pesée dispose de tolérances spécifiques qu’il doit respecter pour être conforme à la réglementation. Nous nous assurons que tous nos équipements respectent scrupuleusement ces tolérances afin d’éviter toute contestation ou litige lors des transactions commerciales.<br>
<br>
<br>
<br>
<div class="titre-un">
    Conformité et audit
</div>
Dans le cadre de la métrologie légale, nous accompagnons nos clients pour garantir leur conformité lors des audits. En tant qu'experts du secteur, nous veillons à ce que votre équipement de pesage soit toujours conforme aux exigences légales, même en cas de modification de la réglementation.<br>
<br>
<br>
<br>
<br>

En tant qu'entreprise spécialisée, nous nous engageons à vous fournir des instruments de pesage de haute qualité et d'une précision irréprochable, tout en assurant leur conformité avec les exigences légales. Vous pouvez ainsi travailler en toute sérénité, en sachant que vos opérations de pesage respectent les obligations légales.
<br>
<br>
<br>
<br>
Nous intervenons sur deux types d'instruments de pesage :
<br>
<br>

<li style="text-align: left;">
    <span style="color: #6878c2; font-weight: bold;">IPFA</span>  (Instruments de Pesage à Fonctionnement Automatique) :<br> Totalisateurs discontinus, doseurs, trieurs-étiqueteurs.</li>
<br>
<li style="text-align: left;">
    <span style="color: #6878c2; font-weight: bold;">IPFNA</span> (Instruments de Pesage à Fonctionnement Non Automatique) : Balances, bascules, ponts bascules, trémies peseuses, etc.
</li>
<br>
<br>

</div>
</div>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>

</template>

<style scoped>

h1 {
    position: relative;
    text-align: center;
    top: 6vh;
    font-size: 3vw;
}

.titre-un {
    position: relative;
    font-size: 2.2vw;
    top: -2vh;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #6878c2;
}

.image-metro {
    width: 100vw; /* Largeur égale à 100% de la largeur de la fenêtre */
    height: 100vh; /* Hauteur égale à 100% de la hauteur de la fenêtre */
    object-fit: cover; /* Recouvre toute la zone tout en conservant les proportions */
    margin: 0; /* Pas d'espaces */
    padding: 0; /* Pas de marges internes */
    top: 0; /* Positionne l'image en haut */
    left: -10%; /* Positionne l'image à gauche */
}

.page-metro {
    position: relative;
    width: 100%;
    top: 10vh;
    height: 320vh;
}

.box-metro {
    position: relative;
    width: 70vw;
    height: 130vh;
    left: 15vw;
    font-size: 1.5vw;
    font-family: 'Lato', sans-serif;
}

</style>