<template>
    <div class="page-acc">
    <div class="slider-container slider-2">
    <input type="radio" name="slider" id="slide1" value="1" v-model="currentSlide">
    <input type="radio" name="slider" id="slide2" value="2" v-model="currentSlide">
    <input type="radio" name="slider" id="slide3" value="3" v-model="currentSlide">
    <input type="radio" name="slider" id="slide4" value="4" v-model="currentSlide">
    <div class="slider">
        <div class="slide">
            <img class="image-acc" src="../../assets/photo/Pont_bascule.jpg" alt="image 1">
        </div>
        <div class="slide">
            <img class="image-acc" src="../../assets/photo/Pont_rail.jpg" alt="image 2">
        </div>
        <div class="slide">
            <img class="image-acc" src="../../assets/photo/Pont_bascule2.jpg" alt="image 3">
        </div>
        <div class="slide">
            <img class="image-acc" src="../../assets/photo/instal_pontbs.jpg" alt="image 4">
        </div>
        <div class="slide">
            <img class="image-acc" src="../../assets/photo/Pont_bascule.jpg" alt="image 1">
        </div>
    </div>
    </div>

        <!-- <div v-if="currentSlide == 1" class="acc-bandeau"> -->
        <div class="acc-bandeau">
            <div class="acc-titre">
                <span style="font-weight: bold; font-size: 3.8vw;">
                    C.N.P.
                </span> 
                <br>
                <br>
                CONSTRUCTIONS NANCEIENNES DE PESAGE <br>
                depuis 1985.
            </div>
    </div>
        <br>
        <br>
        <br>
        <br>
        <div class="pres-cnp">
        CNP, expert en pesage et mesure, propose aux industriels une large gamme de produits alliant fiabilité, performance et simplicité d'utilisation. Que ce soit pour des applications standards ou spécifiques, nos solutions répondent aux exigences les plus pointues, garantissant à la fois précision et haute technicité.        </div>
        <br>
        <div>
        <h1 class="spe-cnp">Nos Spécialités :</h1>
        <br>
        <br>
        <div class="triangleacc-container">
            <router-link to="/PesageStatique">
                <div class="triangleacc triangle1">
                    <div class="label">Pesage Statique</div>
                </div>
            </router-link>
            <router-link to="/Metrologie">
                <div class="triangleacc triangle2">
                    <div class="label">Métrologie Légale</div>
                </div>
            </router-link>
            <router-link to="/Humidimetre">
                <div class="triangleacc triangle3">
                    <div class="label">Humidimètre et analyseur <br> de protéine</div>
                </div>
            </router-link>
        </div>
    </div>
        <br>
        <br>
        <br>
        <h1 class="sec-cnp">Nos Secteurs:</h1>
        <br>
        <br>
        <img class="secteur" src="../../assets/secteurs.png" alt="secteurs">
        <br>
    </div>
</template>

<script>
export default {
  data() {
    return {
      currentSlide: "1",
      totalSlides: 4,
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  methods: {
    startAutoSlide() {
      setInterval(() => {
        // Passer au slide suivant, revenir au premier si on dépasse le dernier
        this.currentSlide = (parseInt(this.currentSlide) % this.totalSlides + 1).toString();
      }, 3000); // Change toutes les 3 secondes
    },
  },
};

</script>

<style>
h1 {
    text-align: center;
}

.page-acc {
    position: relative;
    width: 100%;
    height: 400vh;
}

.acc-titre {
    position: absolute;
    color: white;
    font-size: 2.6vw;
    font-weight: bold;
    top: 15%;
    left: 30px;
    text-align: center;
    z-index: 8;
}

.acc-bandeau {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    width: 60vw;
    height: 32vh;
    top: 40vh;
    left: 18vw;
    text-align: left;
    z-index: 3;
    transition: 0.5s ease-in-out;
}

.acc-bandeau:hover {
    transform: scale(1.2);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
}

.spe-cnp {
    position: absolute;
    padding-left: 41%;
    font-size: 2.6vw;
    font-family: 'Lato', sans-serif;
    top: 43%;
}

.sec-cnp {
    position: absolute;
    padding-left: 41%;
    font-size: 2.6vw;
    font-family: 'Lato', sans-serif;
    top: 70%;
}


/* div {
    text-align: center;
} */


/* Conteneur pour centrer les triangles */
.triangleacc-container {
    position: absolute;
    justify-content: center;
    top: 165vh;
    left: 1vw;
    gap: 2vw;
    padding: 0 5%;
}

.triangleacc-container a:link,
.triangleacc-container a:visited,
.triangleacc-container a:hover,
.triangleacc-container a:active {
    color: inherit;
    text-decoration: none;
}

.triangleacc {
    width: 20vw;
    height: 18vw;
    background-size: cover;
    background-position: center;
    border: 0.1vw solid black;
    clip-path: polygon(
        25% 0%,    /* Coin supérieur gauche */
        75% 0%,    /* Coin supérieur droit */
        100% 50%,  /* Milieu du côté droit */
        75% 100%,  /* Coin inférieur droit */
        25% 100%,  /* Coin inférieur gauche */
        0% 50%     /* Milieu du côté gauche */
    );
    transition: clip-path 1.5s ease-in-out, border-radius 0.2s ease-in-out;
    overflow: hidden;
    position: absolute;
    top: 100vh;
    cursor: pointer;
}

.triangle1 {
    background-image: url('../../assets/photo/Pont_bascule.jpg');
    left: 13.5vw;
    top: 30vh;
}
/* 13 26 39 52 65*/
.triangle2 {
    background-image: url('../../assets/photo/Pont_rail.jpg');
    top: 49vh;
    left: 39.5vw;
} 

.triangle3 {
    background-image: url('../../assets/photo/analyse_prot.jpg');
    background-size: 110%; /* Dézoomer en augmentant la taille de l’image */
    top: 30vh;
    left: 65.5vw;
    background-position: center;
}

/* Transformation en cercle au survol */
.triangleacc:hover {
    border-radius: 80%;
    border: 2px solid black;
    clip-path: circle(45%);
    transform: scale(1.1);
}

/* Bandeau avec texte en bas du triangle */
.triangleacc .label {
    position: absolute;
    bottom: 1em;
    width: 100%;
    padding: 1em 0;
    background-color: #123e63cc;
    color: white;
    text-align: center;
    font-size: 1.5vw;
    z-index: 3;
}

.label:hover {
    text-decoration: underline;
}

.router-link:hover {
    color: #000000;
    border:#000000;
}

.triangleacc:active {
    transform: scale(0.9);
}

.pres-cnp {
    position: absolute;
    width: 50vw;
    height: 30vh;
    top: 115vh;
    left: 26.5vw;
    text-align: center;
    font-size: 1.7vw;
    font-family: 'Lato', sans-serif;
    letter-spacing: 0.05vw;
}

.text-rect {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    height: 200px;
    width: 1000px;
    left: 150px;
    bottom: 200px;
    z-index: 7;
    transition: 0.5s ease-in-out;
}

.text-acc {
    position: absolute;
    text-align: center;
    font-style: oblique;
    left: 150px;
    bottom: 300px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 220%;
    color: rgb(255, 255, 255);
    z-index: 8;
    transition: 0.5s ease-in-out;
}

.wrapper:hover .text-rect,
.wrapper:hover .text-acc {
    transform: scale(1.05);
}

.wrapper:hover .titre-acc {
    transform: translateX(-15px) scale(1.05); /* Adjust the 20px to how far you want it to move */
}

.titre-acc {
    position: absolute;
    text-align: center;
    text-decoration: underline;
    left: 4em;
    bottom: 7.8em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 280%;
    color: rgb(255, 255, 255);
    z-index: 8;
    transition: 0.5s ease-in-out;
}

/* .image-acc {
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* border-image-outset: 10px;
    position: relative;
    top: -10px;
    left: -40px;
    width: 1277px;
    height: 657px;
    align-items: center;
} */


.image-acc {
    width: 100vw; /* Largeur égale à 100% de la largeur de la fenêtre */
    height: 100vh; /* Hauteur égale à 100% de la hauteur de la fenêtre */
    object-fit: cover; /* Recouvre toute la zone tout en conservant les proportions */
    margin: 0; /* Pas d'espaces */
    padding: 0; /* Pas de marges internes */
    top: 0; /* Positionne l'image en haut */
    left: -10%; /* Positionne l'image à gauche */
}

.secteur {
    position: absolute;
    align-items: center;
    width: 60vw;
    height: 60vh;
    left: 20vw;
    top: 75%;
    transition: 0.5s ease-in-out;
}

.secteur:hover {
    transform: scale(1.1);
}

.titres {
    font-size: 150%;
    text-decoration: underline;
    text-align: center;
    color: #123E63;
}
.slider-2 {
    position: absolute; /* Fixe l'élément pour remplir tout l'écran */
    width: 100vw; /* Largeur égale à 100% de la fenêtre */
    height: 100vh; /* Hauteur égale à 100% de la fenêtre */
    top: 0; /* Place le haut de l'élément au sommet de la fenêtre */
    left: 0; /* Place le côté gauche de l'élément à gauche de la fenêtre */
    margin: 0; /* Supprime tout espace supplémentaire */
    overflow: hidden; /* Empêche tout dépassement de contenu */
    display: flex; /* Rend le conteneur flexible */
}

.slider-2 .slider {
    display: flex; /* Aligne les slides horizontalement */
    height: 100%; /* Hauteur complète */
    width: 500%; /* Largeur totale (5 slides, ajuster selon le nombre de slides) */
    animation: slider-2 15s infinite ease-in-out; /* Animation horizontale */
}

.slider-2 .slide {
    flex-shrink: 0; /* Empêche la réduction de taille des slides */
    height: 100%; /* Hauteur complète */
    width: 100vw; /* Largeur égale à la fenêtre */
}

.slider-2 p {
    width: 100%;
    text-align: center;
    font-size: 45px;
    color: #f1f1f1;
}

@keyframes slider-2 {
  0%,
  20% {
    transform: translateX(0);
  }
  25%,
  45% {
    transform: translateX(-20%);
  }
  50%,
  70% {
    transform: translateX(-40%);
  }
  75%,
  95% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(-80%);
  }
}



/* Hide the radio buttons */
input[type="radio"] {
    display: none;
}

/* Use the :checked pseudo-class to control the Y-axis movement */
#slide1:checked ~ .slider {
    transform: translateY(0);
}

#slide2:checked ~ .slider {
    transform: translateY(-100%);
}

#slide3:checked ~ .slider {
    transform: translateY(-200%);
}

#slide4:checked ~ .slider {
    transform: translateY(-300%);
}

</style>